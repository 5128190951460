<template>
  <el-select
    :id="id"
    v-model="countryValue"
    v-cancel-read-only
    :tabindex="tabIndex"
    :placeholder="$t('pages.settings.account.edit.country.title')"
    :class="[addClass, { empty: !modelValue }]"
    :disabled="readonly"
    class="w-full"
    filterable
    autocomplete="off"
    @clear="handleClear"
  >
    <template v-if="modelValue" #prefix>
      <img
        :src="
          require('@/assets/icons/flags/' +
            getISOFromName(modelValue.toLowerCase()) +
            '.svg')
        "
        width="23"
        height="17"
        loading="lazy"
      />
    </template>

    <el-option
      v-for="countryItem in availableCountries"
      :key="countryItem[isoOrName]"
      :label="countryItem.name"
      :value="countryItem[isoOrName]"
    >
      <div class="flex flex-row items-center h-full w-full">
        <img
          :src="
            require('@/assets/icons/flags/' +
              countryItem.iso.toLowerCase() +
              '.svg')
          "
          width="23"
          height="17"
          loading="lazy"
        />
        <span class="ml-2">{{ countryItem.name }}</span>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { countries, euCountries } from '@/utils/address'

export default {
  name: 'ThCountrySelect',
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    tabIndex: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    },
    addClass: {
      type: String,
      default: undefined
    },
    isEu: {
      type: Boolean,
      default: false
    },
    /* Adding iso or name because the staff address expects ISO code and the tax jurisdiction expects a name in lowercase */
    /* This asks for a refactor in the MAIN API to only accept ISO code for both */
    isoOrName: {
      type: String,
      default: 'iso',
      validator(value) {
        return ['iso', 'name'].includes(value)
      }
    }
  },
  emits: ['update:modelValue', 'clear'],
  data() {
    return {
      countries,
      euCountries
    }
  },
  computed: {
    countryValue: {
      get() {
        return this.formatName(this.modelValue)
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    availableCountries() {
      return this.isEu ? this.euCountries : this.countries
    }
  },
  methods: {
    formatName(modelValue) {
      return this.isoOrName === 'iso'
        ? modelValue
        : modelValue?.charAt(0)?.toUpperCase() + modelValue?.slice(1)
    },
    handleClear() {
      this.$emit('clear')
    },
    getISOFromName(value) {
      if (this.isoOrName === 'iso') return value
      const country = this.availableCountries.find(
        (country) => country.name.toLowerCase() === value
      )
      if (!country) throw new Error('country not found')
      return country.iso.toLowerCase()
    }
  }
}
</script>

<style scoped>
.el-select :deep(.el-input__prefix) {
  left: 15px;
  display: flex;
  align-items: center;
}
.el-select:not(.empty) :deep(.el-input__inner) {
  padding-left: 45px;
}
</style>
